import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import {
  getGeoIP,
  getReverseGeocoding,
  getReverseGoogleGeoCoding,
} from '@/api/utils/utils'
import { IStoredGeolocation } from './geolocation.d'
import { computed, ref } from 'vue'
import { ApiResponse } from '@/api/api'
import { GeocodeEarthResponse } from '@/api/utils/geocodeearth'
import { GoogleGeocodeResponse } from '@/api/utils/googlegeocode'

import { useGoogleMapsStore } from '../google-maps/googleMapsStore'

import { useMainSearchBarStore } from '../search-page/mainSearchBarStore'

export const useGeolocationStore = defineStore('geolocation', () => {
  //  The {} is important else the localstorage doesn't store object
  const storedLocation = useLocalStorage<IStoredGeolocation | {}>(
    'userLocation',
    {},
  )
  const storedLocationIsEmpty = computed(() => {
    return (
      !storedLocation.value ||
      Object.values(storedLocation.value).length == 0 ||
      (storedLocation.value as any).city == null ||
      (storedLocation.value as any).coords.latitude == null ||
      (storedLocation.value as any).coords.longitude == null
    )
  })
  const updateStoredLocation = (coordsAndCity: IStoredGeolocation) => {
    storedLocation.value = coordsAndCity
  }

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  }

  const loading = ref(false)

  const askGeolocation = async () => {
    loading.value = true
    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition(
        async position => {
          if (position.coords) {
            storedLocation.value = {
              city: '', // TODO get the city by geo loc
              coords: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
            }
          }
          // get address and city
          await onGetPositionFromClient()
        },
        async error => {
          // console.error('Geolocation error:', error)
          await fetchDefaultLocation()
        },
        options,
      )
    } else {
      await fetchDefaultLocation()
    }
    loading.value = false
  }

  const fetchDefaultLocation = async () => {
    try {
      const response = await getGeoIP()
      storedLocation.value = {
        ...response.data,
        coords: {
          latitude: response.data.latitude,
          longitude: response.data.longitude,
        },
      }
    } catch (error) {
      console.error('Failed to fetch default location', error)
    }
  }

  const initLocationService = async () => {
    // the geolocation is also set in handleLoginResponse on login or refresh-token
    if (storedLocationIsEmpty.value) {
      await fetchDefaultLocation()
    }
  }

  const getLat = (): number => {
    return (storedLocation.value as any)?.coords?.latitude
  }

  const getLon = (): number => {
    return (storedLocation.value as any)?.coords?.longitude
  }

  const onGetPositionFromClient_old = async () => {
    const mainSearchBarStore = useMainSearchBarStore()
    loading.value = true
    /** Get address or city from geolocation */
    const response: ApiResponse<GeocodeEarthResponse> =
      await getReverseGeocoding(getLat(), getLon())

    loading.value = false
    const address = response.data.features[0].properties.label
    ;(storedLocation.value as any).city =
      response.data.features[0].properties.locality ?? ''
    mainSearchBarStore.searchLocation = address
    // mainSearchBarStore.searchLocation = {
    //   title: address,
    //   value: address,
    //   label: address,
    // } as any
  }

  const extractCityFromAddress = (address: string): string | null => {
    if (!address) {
      return null
    }

    const parts = address.split(',')

    if (parts.length > 1) {
      const city = parts[1].trim()
      console.log('Extracted city:', city)
      return city
    }

    return null
  }

  const onGetPositionFromClient = async () => {
    const mainSearchBarStore = useMainSearchBarStore()
    loading.value = true

    const GoogleMapStore = useGoogleMapsStore()

    const response: GoogleGeocodeResponse =
      await GoogleMapStore.getReverseGoogleGeoCoding(getLat(), getLon())

    loading.value = false
    const address = response.results[0].formatted_address as string

    ;(storedLocation.value as any).city = extractCityFromAddress(address) ?? ''

    console.log('address', extractCityFromAddress(address))

    mainSearchBarStore.searchLocation = address.split(' ').slice(1).join(' ')
  }

  return {
    storedLocation,
    updateStoredLocation,
    storedLocationIsEmpty,
    askGeolocation,
    loading,
    fetchDefaultLocation,
    initLocationService,
    getLat,
    getLon,
    onGetPositionFromClient,
  }
})
