<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import createHttpClient from '@/api/httpClient'
import MessageService from '@/components/feedback/message/messageService'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import EventBus from '@/utils/eventBus'

const subscriptionStore = useSubscriptionStore()
const { t: $t, d, n } = useI18n()
const httpClient = createHttpClient()

// Variables réactives
const discountCodes = ref([])
const discountCodeInput = ref('')
const loading = ref(false)
const showAddModal = ref(false) // Modal d'ajout
const showDeleteModal = ref(false) // Modal de confirmation de suppression
const codeToDelete = ref('') // Code à supprimer
const msgError = ref('')

discountCodes.value = subscriptionStore.discountCodes
// Requête pour récupérer les codes de réduction
// const fetchDiscountCodes = async () => {
//   try {
//     loading.value = true
//     const { data } = await httpClient.get('/discount-code/')
//     discountCodes.value = data
//   } catch (error) {
//     console.error(
//       'Erreur lors de la récupération des codes de réduction :',
//       error,
//     )
//   } finally {
//     loading.value = false
//   }
// }

// Requête pour appliquer un code de réduction
const applyDiscountCode = async (code: string) => {
  if (!code) {
    alert($t("Veuillez saisir un code avant de l'appliquer."))
    return
  }
  try {
    loading.value = true

    // Envoi de la requête POST avec le code de réduction dans le corps de la requête
    const { data } = await httpClient.post(
      '/discount-code/create',
      {
        code, // Corps de la requête contenant le code de réduction
      },
      { headers: { 'Content-Type': 'application/json' } },
    )

    subscriptionStore.fetchDiscountCodes() // Mettre à jour la liste des codes après application
    subscriptionStore.fetchSubscriptionStatus()
    MessageService.success('Le code a été appliqué avec succès !')
    showAddModal.value = false
    discountCodeInput.value = ''
    return data
  } catch (error) {
    msgError.value = error.response.data.message
    MessageService.error(
      "Une erreur est survenue lors de l'application du code",
    )
    console.log(error.response.data.message)
  } finally {
    loading.value = false
  }
}

// Requête pour supprimer un code de réduction
const deleteDiscountCode = async () => {
  try {
    loading.value = true
    await httpClient.delete(`/discount-code/${codeToDelete.value}`)
    alert($t('Le code a été supprimé avec succès !'))
    subscriptionStore.fetchDiscountCodes() // Mettre à jour la liste des codes après suppression
    showDeleteModal.value = false
  } catch (error) {
    console.error('Erreur lors de la suppression du code :', error)
    alert($t('Une erreur est survenue lors de la suppression du code.'))
  } finally {
    loading.value = false
  }
}

// Chargement initial des codes de réduction
onMounted(() => {
  subscriptionStore.fetchDiscountCodes()
})
</script>

<template>
  <div class="mt-8 d-flex flex-column ga-3">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <span class="text-dark-green font-weight-medium">{{
          $t('discounts')
        }}</span>
      </div>
      <v-btn
        color="grey-lighten-3"
        append-icon="mdi-plus"
        @click="showAddModal = true"
        class="mb-4 add-coupon-btn"
        icon="mdi-plus"
      ></v-btn>
    </div>

    <div>
      Saisissez un code promotionnel pour bénéficier d'une réduction. Vous
      trouverez ci-dessous les détails des réductions appliquées.
    </div>
    <div class="discount-code-management">
      <!-- Section d'ajout de code -->

      <!-- Tableau des codes existants -->
      <v-data-table
        :items="subscriptionStore.discountCodes"
        :headers="[
          { title: 'Code', value: 'code' },
          { title: 'Réduction', value: 'value' },
          { title: 'Date de début', value: 'startDate' },
          { title: 'Date de fin', value: 'endDate', align: 'center' },
        ]"
        class="bg-white rounded-sm"
        :hide-default-footer="discountCodes.length < 10"
      >
        <template #item.value="{ item }">
          <span>
            <span v-if="item.type === 'percentage'">{{ item.value }}</span>
            <span v-else>{{ item.value / 100 }}</span>

            <span v-if="item.type === 'percentage'">% de réduction</span>
            <span v-else>€ de réduction</span>
          </span>
        </template>

        <template #item.startDate="{ item }">
          <span>
            {{ d(new Date(item.startDate), 'short') }}
          </span>
        </template>

        <template #item.endDate="{ item }">
          <span v-if="item.endDate">
            {{ d(new Date(item.endDate), 'short') }}
          </span>
          <span v-else> - </span>
        </template>

        <!-- <template #item.actions="{ item }">
          <v-btn color="red" @click="() => { codeToDelete.value = item.code; showDeleteModal.value = true }">
            Supprimer
          </v-btn>
        </template> -->
      </v-data-table>
    </div>

    <!-- Modal d'ajout de code -->
    <v-dialog
      :model-value="showAddModal"
      max-width="550px"
      persistent
      transition="dialog-top-transition"
    >
      <v-card class="timeslot-modal">
        <v-card-title class="modal-header">
          <div></div>
          <span class="modal-title text-primary"
            >Ajouter un code de réduction</span
          >
          <v-btn
            variant="text"
            color="primary"
            icon="mdi-close-circle-outline"
            @click="showAddModal = false"
          >
          </v-btn>
        </v-card-title>

        <v-card-text class="d-flex flex-column">
          <v-card-subtitle class="modal-subtitle mb-5"> </v-card-subtitle>

          <label>Veuillez saisir le code de réduction à appliquer</label>
          <v-text-field
            v-model="discountCodeInput"
            label="Code de réduction"
            outlined
            dense
            required
            class="mt-4"
            :error-messages="msgError"
          />
        </v-card-text>

        <v-card-actions class="modal-actions">
          <v-btn
            class="w-50"
            color="grey"
            variant="flat"
            @click="showAddModal = false"
            >Annuler</v-btn
          >
          <v-btn
            class="w-50"
            variant="flat"
            color="secondary"
            @click="applyDiscountCode(discountCodeInput)"
            :loading="loading"
            >Appliquer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="showAddModal" max-width="500px">
      <v-card>
        <v-card-title class="headline">Ajouter un code de réduction</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="discountCodeInput"
            label="Code de réduction"
            outlined
            dense
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showAddModal = false">Annuler</v-btn>
          <v-btn color="green" @click="applyDiscountCode(discountCodeInput)">Appliquer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- Modal de confirmation de suppression -->
    <v-dialog v-model="showDeleteModal" max-width="400px">
      <v-card>
        <v-card-title class="headline"
          >Confirmation de suppression</v-card-title
        >
        <v-card-text>
          Êtes-vous sûr de vouloir supprimer ce code de réduction ?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showDeleteModal = false">Annuler</v-btn>
          <v-btn color="red" @click="deleteDiscountCode">Supprimer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.v-btn {
  margin: 8px 0;
}

.v-card-text {
  background-color: white;
}
.timeslot-modal {
  border: 1px solid #d3e2e9;
}

.modal-header {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(var(--v-theme-surface-lighten-1)) !important;
}

.warning-icon {
  margin: 10px;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.modal-subtitle {
  color: #ff6f00;
  text-align: center;
  font-weight: 500;
}

.timeslot-info {
  background-color: #e0f7fa;
  padding: 16px;
  border-radius: 8px;
  font-size: 14px;
}

.modal-actions {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  padding-inline: 30px;
}

.modal-actions v-btn {
  margin-left: 10px;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center;
}

.add-coupon-btn {
  font-size: 14px;
  width: 36px;
  height: 36px;
}

:deep(.v-table thead) {
  background-color: #f5f5f5 !important;
  padding: 20px, 32px, 20px, 32px !important;
  border-radius: 10px;
  color: #616161;
  font-size: 16px;
}

:deep(.v-table__wrapper) {
  border-radius: 15px !important;
}
</style>
