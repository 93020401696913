<template>
  <v-container fluid class="pa-0">
    <v-row class="d-flex">
      <v-col sm="12" md="4" class="py-0" v-if="!mobile">
        <template v-if="profileLoading">
          <v-skeleton-loader
            class="border-md border-opacity-90 rounded-xl overflow-y-hidden"
            elevation="0"
            color="white"
            min-width="365"
            type="card"
          />
          <!-- <div class="text-center font-italic">
            {{ $t('profileForm.loadProfils') }}...
          </div> -->
        </template>

        <template v-else>
          <div v-if="profiles.length">
            <div class="d-flex flex-column ga-4">
              <template v-for="(profil, idx) in profiles" :key="idx">
                <v-card
                  v-if="profil.id"
                  @click="profileSelected(profil, idx)"
                  elevation="0"
                  color="white"
                  height="auto"
                  class="d-flex flex-column ga-4 border-md border-opacity-90 rounded-xl pa-4 overflow-y-hidden"
                  :class="{
                    'border-md border-teal-green border-opacity-100':
                      profil.id === currentProfileId,
                  }"
                  style="min-height: 260px"
                >
                  <div class="d-flex align-center">
                    <v-avatar size="64" class="border-md">
                      <PractitionerAvatar
                        width="64"
                        height="64"
                        v-show="!profil.avatar"
                        color="#9E9E9E"
                      />
                      <v-img
                        v-show="profil.avatar"
                        :src="profil.avatar?.url"
                        :cover="true"
                      ></v-img>
                    </v-avatar>
                    <div class="ml-2 w-100">
                      <div class="d-flex align-center justify-space-between">
                        <span
                          class="text-capitalize"
                          style="color: #9e9e9e; font-size: 13px"
                        >
                          {{ $t('dashboard.profile.profile') }} {{ idx + 1 }}
                        </span>
                        <div class="d-flex align-center ga-1">
                          <v-btn
                            v-if="profil.profileAddresses[0]"
                            :to="{
                              name: 'PractitionerPublicProfile',
                              params: {
                                discipline: profil.mainDiscipline.slug,
                                city: profil.profileAddresses[0]?.address?.city,
                                practitionerSlug: profil.slug,
                              },
                            }"
                            target="_blank"
                            slim
                            density="compact"
                            color="#616161"
                            icon="mdi-eye-outline"
                            variant="text"
                          />
                          <v-btn
                            density="comfortable"
                            color="white"
                            @click="profileSelected(profil, idx)"
                            icon
                            size="small"
                            class="cursor-pointer"
                          >
                            <template #default>
                              <PencilIcon size="24" />
                            </template>
                          </v-btn>

                          <v-btn
                            v-if="false"
                            class="cursor-pointer"
                            @click="handleDeleteProfile(profil, idx)"
                            icon
                            size="small"
                            density="comfortable"
                            color="white"
                          >
                            <template #default>
                              <CloseIcon size="24" />
                            </template>
                          </v-btn>
                        </div>
                      </div>
                      <div class="text-dark-green profile-slug">
                        {{ profil.slug }}
                      </div>
                    </div>
                  </div>

                  <div class="d-flex flex-wrap ga-2">
                    <v-chip
                      v-for="(discipline, idx) in profil.disciplines"
                      :key="idx"
                      color="#F5F5F5"
                      variant="flat"
                      rounded="md"
                      density="compact"
                    >
                      <span class="text-gray-80">
                        {{ discipline.name }}
                      </span>
                    </v-chip>
                  </div>

                  <div class="profil-tagline">
                    {{ profil?.tagline ?? '******' }}
                  </div>
                </v-card>

                <!-- profile enrégistré en mode Draft  -->
                <div v-else>
                  <v-card
                    @click="profileSelected(profil, idx)"
                    elevation="0"
                    color="white"
                    height="auto"
                    class="d-flex flex-column ga-4 border-md border-opacity-90 rounded-xl pa-4 overflow-y-hidden"
                    :class="{
                      'border-md border-teal-green border-opacity-100':
                        profil.draft.id === DraftId,
                    }"
                    style="min-height: 260px"
                  >
                    <div class="d-flex align-center">
                      <v-avatar size="64" class="border-md">
                        <PractitionerAvatar
                          width="64"
                          height="64"
                          v-show="!profil.draft.avatar"
                          color="#9E9E9E"
                        />
                        <v-img
                          v-show="profil.draft.avatar"
                          :src="profil.draft.avatar?.url"
                          :cover="true"
                        ></v-img>
                      </v-avatar>
                      <div class="ml-2 w-100">
                        <div class="d-flex align-center justify-space-between">
                          <span
                            class="text-capitalize"
                            style="color: #9e9e9e; font-size: 13px"
                          >
                            {{ $t('dashboard.profile.profile') }} {{ idx + 1 }}
                          </span>
                          <div class="d-flex align-center ga-1">
                            <v-btn
                              density="comfortable"
                              color="white"
                              @click="profileSelected(profil, idx)"
                              icon
                              size="small"
                              class="cursor-pointer"
                            >
                              <template #default>
                                <PencilIcon size="24" />
                              </template>
                            </v-btn>
                          </div>
                        </div>
                        <div class="text-dark-green profile-slug">
                          <span>Profil Non publié</span>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex flex-wrap ga-2">
                      <v-chip
                        v-for="(discipline, idx) in profil.draft?.disciplines"
                        :key="idx"
                        color="#F5F5F5"
                        variant="flat"
                        rounded="md"
                        density="compact"
                      >
                        <span class="text-gray-80">
                          {{ discipline?.name }}
                        </span>
                      </v-chip>
                    </div>

                    <div class="profil-tagline">
                      <!-- Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatem vitae explicabo libero quas ad at necessitatibus deleniti impedit esse fugiat autem deserunt repudiandae, officiis et rerum velit iste ipsa ut. -->
                      {{ profil?.draft.tagline ?? '******' }}
                    </div>
                  </v-card>
                </div>
              </template>
            </div>
          </div>

          <div v-else class="text-center">
            {{ $t('profileForm.noProfil') }}
          </div>
        </template>
      </v-col>

      <v-col sm="12" md="8" class="py-0">
        <template v-if="profileLoading">
          <v-skeleton-loader
            class="border-md border-opacity-90 rounded-xl overflow-y-hidden"
            elevation="0"
            color="white"
            min-width="80%"
            type="card"
          />
        </template>
        <div
          v-else
          class="border w-100 pb-5 mb-5"
          style="background-color: #f5f5f5"
          id="newProfil"
        >
          <div
            class="detail-title overflow-y-auto d-sm-flex justify-space-between align-center"
          >
            <div>
              <template
                v-if="
                  route.query.selected_profile === 'New' || !profiles.length
                "
              >
                <div>Nouveau profil</div>
              </template>
              <div v-else>
                {{ $t('profileForm.profil_detail') }}
                <span v-if="selectedIndex">{{ selectedIndex }}</span>
              </div>
            </div>

            <div class="d-flex flex-wrap ga-2">
              <v-btn
                :block="mobile"
                @click="(isDraft = false), submit()"
                :loading="submitLoading"
                density="comfortable"
                color="blue"
                rounded="lg"
                class="w-auto"
              >
                <span style="font-size: 14px">{{
                  $t('profileForm.save_publish')
                }}</span>
              </v-btn>

              <v-btn
                :block="mobile"
                v-if="route.query.selected_profile !== 'New'"
                size="small"
                variant="outlined"
                color="red"
                rounded="false"
                @click="
                  handleDeleteProfile(
                    profiles[selectedIndex - 1],
                    Number(route.query.selected_profile) - 1,
                  )
                "
              >
                {{ $t('accountForm.avatar.deleteProfil') }}
              </v-btn>
            </div>
          </div>

          <div class="pa-sm-5 pa-2 block-profile">
            <!-- block photo de profile  -->
            <v-card
              v-if="rootErrors.length"
              color="#FFE8E8"
              class="pa-2 elevation-0 mb-2"
            >
              <error-list :errors="rootErrors" />
            </v-card>

            <v-card class="block-content py-5 pl-5" color="white" elevation="0">
              <div class="d-sm-flex ga-2 align-center justify-space-between">
                <div class="d-sm-flex ga-2">
                  <div
                    style="
                      position: relative;
                      width: fit-content;
                      height: fit-content;
                    "
                  >
                    <v-avatar
                      size="80"
                      class="border-sm border-gray-ligth border-opacity-100"
                    >
                      <PractitionerAvatar
                        v-show="!form.avatar.src"
                        color="#9E9E9E"
                      />

                      <v-img
                        width="64"
                        height="64"
                        v-show="form.avatar.src"
                        :src="form.avatar.src"
                        :cover="true"
                      ></v-img>
                    </v-avatar>

                    <div
                      class="elevation-0 visio-icon cursor-pointer"
                      @click="() => handleUploadAvatar()"
                    >
                      <CameraIcon :width="10.8" :height="10.8" />
                    </div>
                  </div>

                  <div style="font-size: 13px" class="d-flex flex-column ga-1">
                    <div class="text-primary" style="font-weight: 400">
                      {{ $t('profileForm.picture') }}
                    </div>
                    <div class="d-flex flex-column ga-1">
                      <span class="text-gray-80">
                        {{ $t('accountForm.avatar.caption') }}
                      </span>

                      <div class="d-flex flex-wrap ga-2">
                        <v-btn
                          size="small"
                          color="blue"
                          rounded="false"
                          @click="() => handleUploadAvatar()"
                        >
                          {{ $t('accountForm.avatar.updateBtn2') }}
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="route.query.selected_profile !== 'New'"
                  class="align-center ga-2 d-flex mt-sm-0 mt-2"
                  :class="!isSynced ? 'c-badge-draft' : 'c-badge'"
                >
                  <SavePublishIcon v-if="isSynced" />

                  <CheckOutlineIcon v-else />

                  <span>{{
                    isSynced
                      ? 'Enregistré et publié'
                      : 'Enregistré en brouillon'
                  }}</span>
                </div>
              </div>
            </v-card>

            <v-card color="white" elevation="0" class="mt-5">
              <div
                class="d-sm-flex flex-wrap justify-space-between tab-header pa-sm-5 pa-2 align-center"
              >
                <div
                  class="d-flex ga-2 pa-1 rounded-lg overflow-x-auto"
                  style="color: #757575; background-color: #f5f5f5"
                >
                  <div
                    :class="
                      route.query.tab === 'info'
                        ? 'border bg-white rounded-lg pa-1'
                        : ''
                    "
                    class="d-flex align-center ga-1 cursor-pointer"
                    @click="changeTab('info')"
                  >
                    <InformationIcon
                      :color="
                        route.query.tab === 'info' ? '#424242' : '#757575'
                      "
                    />
                    <span>{{ $t('profileForm.info') }}</span>
                    <v-badge
                      v-if="block1StatusColor"
                      :color="block1StatusColor"
                      inline
                      dot
                    />
                  </div>
                  <div
                    :class="
                      route.query.tab === 'presentation'
                        ? 'border bg-white rounded-lg pa-1'
                        : ''
                    "
                    class="d-flex align-center ga-1 cursor-pointer"
                    @click="changeTab('presentation')"
                  >
                    <PresentationIcon
                      :color="
                        route.query.tab === 'presentation'
                          ? '#424242'
                          : '#757575'
                      "
                    />
                    <span>{{ $t('profileForm.presentation_title') }}</span>
                    <v-badge
                      v-if="block2StatusColor"
                      :color="block2StatusColor"
                      inline
                      dot
                    />
                  </div>
                  <div
                    :class="
                      route.query.tab === 'degrees'
                        ? 'border bg-white rounded-lg pa-1'
                        : ''
                    "
                    class="d-flex align-center ga-1 cursor-pointer"
                    @click="changeTab('degrees')"
                  >
                    <DiplomeIcon
                      :color="
                        route.query.tab === 'degrees' ? '#424242' : '#757575'
                      "
                    />
                    <span>{{ $t('profileForm.degree') }}</span>
                    <v-badge
                      v-if="block3StatusColor"
                      :color="block3StatusColor"
                      inline
                      dot
                    />
                  </div>
                  <div
                    :class="
                      route.query.tab === 'pictures'
                        ? 'border bg-white rounded-lg pa-1'
                        : ''
                    "
                    class="d-flex align-center ga-1 cursor-pointer"
                    @click="changeTab('pictures')"
                  >
                    <ImageIcon
                      :color="
                        route.query.tab === 'pictures' ? '#424242' : '#757575'
                      "
                    />
                    <span>{{ $t('profileForm.photos_title') }}</span>
                    <!-- <v-badge color="red" inline dot /> -->
                  </div>
                </div>
              </div>
              <v-divider color="#757575" class="" />

              <div class="px-sm-5 px-2 pt-2">
                <!-- tab information  -->

                <v-expand-transition>
                  <form-block
                    v-show="route.query.tab === 'info'"
                    id="newProfil"
                    ref="formBlock1"
                    :is-new="currentProfileId === null"
                    @update:is-valid="isBaseForm1Valid = $event"
                  >
                    <div class="d-flex flex-column ga-2">
                      <div>
                        <div class="tab-title py-2">
                          {{ $t('profileForm.presentation_title') }}
                        </div>

                        <div>
                          <label>{{
                            $t('profileForm.disciplines.label')
                          }}</label>
                          <autocomplete-chips
                            rounded="lg"
                            density="compact"
                            :key="disciplineEditorKey"
                            v-model="form.disciplines.value"
                            :items="disciplines"
                            item-title="name"
                            item-value="id"
                            :rules="disciplinesRules"
                            :error-messages="form.disciplines.error"
                            counter="3"
                            :persistent-counter="
                              form.disciplines.value.length >= MAX_SPECIALITIES
                            "
                            color="secondary"
                            :no-data-text="
                              $t('profileForm.disciplines.noDataText')
                            "
                          >
                          </autocomplete-chips>
                          <i18n-t keypath="profileForm.disciplines.suggest">
                            <template #link>
                              <a
                                href="#"
                                @click.prevent="suggestDisciplineDialog = true"
                              >
                                {{
                                  $t('profileForm.disciplines.suggestLinkLabel')
                                }}
                              </a>
                            </template>
                          </i18n-t>
                        </div>
                      </div>

                      <suggest-discipline-dialog
                        :dialog="suggestDisciplineDialog"
                        @update:dialog="suggestDisciplineDialog = $event"
                      ></suggest-discipline-dialog>

                      <div v-if="form.disciplines.value.length > 1">
                        <label>{{
                          $t('profileForm.mainDiscipline.label')
                        }}</label>
                        <v-select
                          rounded="lg"
                          density="compact"
                          v-model="form.mainDiscipline.value"
                          :hint="$t('profileForm.mainDiscipline.hint')"
                          persistent-hint
                          :items="mainDisciplinesItems"
                          :error-messages="form.mainDiscipline.error"
                          item-title="name"
                          item-value="id"
                        ></v-select>
                      </div>

                      <div>
                        <label>{{
                          $t('profileForm.practitionerServices.label')
                        }}</label>
                        <autocomplete-chips
                          rounded="lg"
                          density="compact"
                          v-model="form.practitionerServices.value"
                          :hint="$t('profileForm.practitionerServices.hint')"
                          persistent-hint
                          :items="practitionerServices"
                          item-title="name"
                          item-value="id"
                          :rules="practitionerServicesRules"
                          :error-messages="form.practitionerServices.error"
                          color="secondary"
                          :return-object="false"
                          :no-data-text="
                            $t('profileForm.practitionerServices.noDataText')
                          "
                        ></autocomplete-chips>
                      </div>

                      <div>
                        <label>{{
                          $t('profileForm.targetAudiences.label')
                        }}</label>
                        <autocomplete-chips
                          rounded="lg"
                          density="compact"
                          :key="audiencesEditorKey"
                          v-model="form.targetAudiences.value"
                          :items="targetAudiences"
                          item-title="name"
                          item-value="id"
                          :rules="targetAudiencesRules"
                          :error-messages="form.targetAudiences.error"
                          color="secondary"
                        >
                        </autocomplete-chips>
                      </div>

                      <div>
                        <label>{{
                          $t('profileForm.paymentMethods.label')
                        }}</label>
                        <autocomplete-chips
                          rounded="lg"
                          density="compact"
                          :key="paymentEditorKey"
                          v-model="form.paymentMethods.value"
                          :items="paymentMethods"
                          item-title="name"
                          item-value="id"
                          :rules="paymentMethodsRules"
                          :error-messages="form.paymentMethods.error"
                          color="secondary"
                        >
                        </autocomplete-chips>
                      </div>

                      <div>
                        <label>{{
                          $t('profileForm.spokenLanguages.label')
                        }}</label>
                        <autocomplete-chips
                          rounded="lg"
                          density="compact"
                          :key="spokenLanguagesEditorKey"
                          v-model="form.spokenLanguages.value"
                          :items="languages"
                          item-title="nativeName"
                          item-value="code"
                          :rules="spokenLanguagesRules"
                          :error-messages="form.spokenLanguages.error"
                          color="secondary"
                        >
                        </autocomplete-chips>
                      </div>

                      <div>
                        <label>{{ $t('profileForm.email.label') }}</label>
                        <v-text-field
                          rounded="lg"
                          density="compact"
                          v-model="form.email.value"
                          :hint="$t('profileForm.email.hint')"
                          :rules="emailRules"
                          :error-messages="form.email.error"
                          color="secondary"
                        ></v-text-field>
                      </div>

                      <div>
                        <label>{{ $t('profileForm.phone.label') }}</label>
                        <phone
                          v-model="form.phone.value"
                          :hint="$t('profileForm.phone.hint')"
                          :error-messages="form.phone.error"
                          :show-dial-code="false"
                          rounded
                          required
                          @change="onPhoneChange"
                          @valide="form.phone.valid = $event"
                        ></phone>
                      </div>

                      <div>
                        <label>{{ $t('profileForm.website') }}</label>
                        <v-text-field
                          rounded="lg"
                          density="compact"
                          v-model="form.webSite.value"
                          :rules="websiteRules"
                          :error-messages="form.webSite.error"
                          color="secondary"
                          placeholder="https://"
                        ></v-text-field>
                      </div>
                    </div>
                  </form-block>
                </v-expand-transition>

                <!-- tab presentation  -->
                <v-expand-transition>
                  <form-block
                    v-show="route.query.tab === 'presentation'"
                    ref="formBlock2"
                    @update:is-valid="isForm2Valid = $event"
                  >
                    <div>
                      <div class="tab-title py-2">Présentation</div>
                      <div>
                        <label>{{ $t('profileForm.tagline.label') }}</label>
                        <v-textarea
                          v-model="form.tagline.value"
                          :hint="$t('profileForm.tagline.hint')"
                          :rules="taglineRules"
                          :error-messages="form.tagline.error"
                          color="secondary"
                          rows="2"
                          :counter="SHORT_PRESENTATION_MAX_LENGTH"
                          persistent-counter
                        ></v-textarea>
                      </div>

                      <div>
                        <label>{{
                          $t('profileForm.mainDescription.label')
                        }}</label>
                        <rich-editor
                          ref="mainDescriptionEditor"
                          :key="mainDescriptionEditorKey"
                          v-model:content="form.mainDescription.value"
                          contentType="html"
                          :hint="$t('profileForm.mainDescription.hint')"
                          :rules="mainDescriptionRules"
                          :error-messages="form.mainDescription.error"
                          color="secondary"
                          :counter="LONG_PRESENTATION_MAX_LENGTH"
                          @is-valid="form.mainDescription.valid = $event"
                        >
                        </rich-editor>
                      </div>

                      <div>
                        <label>{{
                          $t('profileForm.sessionDescription.label')
                        }}</label>
                        <rich-editor
                          v-model:content="form.sessionDescription.value"
                          :key="sessionDescriptionEditorKey"
                          contentType="html"
                          :hint="$t('profileForm.sessionDescription.hint')"
                          :rules="sessionDescriptionRules"
                          :error-messages="form.sessionDescription.error"
                          color="secondary"
                          :counter="SESSION_DESCRIPTION_MAX_LENGTH"
                          @is-valid="form.sessionDescription.valid = $event"
                        >
                        </rich-editor>
                      </div>

                      <div>
                        <label>{{
                          $t('profileForm.expectedBenefits.label')
                        }}</label>
                        <rich-editor
                          v-model:content="form.expectedBenefits.value"
                          :key="expectedBenefitsEditorKey"
                          contentType="html"
                          :hint="$t('profileForm.expectedBenefits.hint')"
                          :rules="expectedBenefitsRules"
                          :error-messages="form.expectedBenefits.error"
                          color="secondary"
                          :counter="EXPECTED_BENEFITS_MAX_LENGTH"
                          @is-valid="form.expectedBenefits.valid = $event"
                        >
                        </rich-editor>
                      </div>
                    </div>
                  </form-block>
                </v-expand-transition>

                <!-- tab diplomes  -->
                <v-expand-transition>
                  <form-block
                    v-show="route.query.tab === 'degrees'"
                    ref="formBlock3"
                    @update:is-valid="isForm3Valid = $event"
                  >
                    <div class="d-flex justify-space-between align-center">
                      <div class="tab-title py-2">
                        <span>Diplômes</span>
                      </div>
                      <div
                        class="cursor-pointer add-img-btn"
                        v-if="form.degrees.value.length < 5"
                        @click="addDegree"
                      >
                        <v-icon>mdi-plus</v-icon>
                        <span>
                          {{
                            form.degrees.value.length < 1
                              ? $t('profileForm.degrees.addBtn')
                              : $t('profileForm.degrees.addAnotherBtn')
                          }}
                        </span>
                      </div>
                    </div>

                    <v-tabs
                      slider-color="secondary"
                      selected-class="active-tab"
                      v-model="tab"
                      bg-color="white"
                    >
                      <template v-for="(degree, idx) in form.degrees.value">
                        <v-tab :value="idx">Diplôme: 0{{ idx + 1 }}</v-tab>
                      </template>
                    </v-tabs>

                    <v-tabs-window v-model="tab">
                      <v-tabs-window-item
                        v-for="(degreeForm, index) in form.degrees.value"
                        :key="index"
                        :value="index"
                      >
                        <v-row>
                          <v-col cols="12">
                            <v-sheet
                              border
                              rounded="lg"
                              class="pa-2 mt-3"
                              color="transparent"
                            >
                              <div>
                                <label
                                  >{{
                                    $t('profileForm.degrees.schoolName.label')
                                  }}(*)</label
                                >
                                <v-text-field
                                  rounded="lg"
                                  density="compact"
                                  v-model="degreeForm.schoolName.value"
                                  :rules="degreeRules"
                                  color="secondary"
                                ></v-text-field>
                              </div>

                              <div>
                                <label
                                  >{{
                                    $t('profileForm.degrees.discipline.label')
                                  }}(*)</label
                                >
                                <v-text-field
                                  rounded="lg"
                                  density="compact"
                                  v-model="degreeForm.discipline.value"
                                  :rules="degreeRules"
                                  color="secondary"
                                ></v-text-field>
                              </div>

                              <div class="d-sm-flex ga-2">
                                <div class="w-sm-50">
                                  <label
                                    >{{
                                      $t(
                                        'profileForm.degrees.graduationYear.label',
                                      )
                                    }}(*)</label
                                  >
                                  <v-select
                                    rounded="lg"
                                    density="compact"
                                    v-model="degreeForm.graduationYear.value"
                                    :items="years"
                                    :rules="requiredRules"
                                    color="secondary"
                                  ></v-select>
                                </div>

                                <div class="w-sm-50 d-flex ga-2">
                                  <div class="w-100">
                                    <label
                                      >{{
                                        $t(
                                          'profileForm.degrees.durationValue.label',
                                        )
                                      }}(*)</label
                                    >
                                    <v-select
                                      rounded="lg"
                                      density="compact"
                                      v-model="degreeForm.durationValue.value"
                                      bg-color="transparent"
                                      color="secondary"
                                      flat
                                      :rules="requiredRules"
                                      :placeholder="
                                        $t(
                                          'profileForm.degrees.durationValue.label',
                                        )
                                      "
                                      :items="
                                        Array.from(
                                          { length: 10 },
                                          (v, i) => i + 1,
                                        )
                                      "
                                      style="min-width: 100px"
                                    ></v-select>
                                  </div>

                                  <div class="w-100">
                                    <label>
                                      {{
                                        $t(
                                          'profileForm.degrees.durationUnit.years',
                                        )
                                      }}
                                    </label>
                                    <v-select
                                      rounded="lg"
                                      v-model="degreeForm.durationUnit.value"
                                      class="flex-0-0"
                                      :items="durationUnits"
                                      item-title="name"
                                      item-value="id"
                                      bg-color="transparent"
                                      single-line
                                      flat
                                      density="compact"
                                    ></v-select>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <label>{{
                                  $t('profileForm.degrees.description.label')
                                }}</label>
                                <v-text-field
                                  rounded="lg"
                                  density="compact"
                                  v-model="degreeForm.title.value"
                                  :placeholder="
                                    $t('profileForm.degrees.description.label')
                                  "
                                  :rules="degreeRules"
                                  color="secondary"
                                ></v-text-field>
                              </div>

                              <v-row>
                                <v-col cols="12">
                                  <div ref="uppyDocuments"></div>
                                  <v-chip
                                    v-for="(document, index) in degreeForm
                                      .documents.value"
                                    :key="document.src"
                                    class="ma-1 cursor-pointer"
                                    color="secondary"
                                    closable
                                    @click="() => seeDocument(document.src)"
                                    @click:close="
                                      degreeForm.documents.value.splice(
                                        index,
                                        1,
                                      )
                                    "
                                  >
                                    {{ document.name }}
                                  </v-chip>
                                </v-col>
                                <v-col cols="12">
                                  <v-btn
                                    block
                                    rounded="lg"
                                    v-if="
                                      degreeForm.documents.value.length <
                                      MAX_DOCUMENTS
                                    "
                                    color="#C2C2C2"
                                    @click="
                                      handleUploadDegreeDocuments(
                                        degreeForm.documents.value,
                                      )
                                    "
                                  >
                                    <div class="d-flex align-center ga-1">
                                      <v-icon color="#424242"
                                        >mdi-plus-circle-outline</v-icon
                                      >
                                      <span
                                        style="color: #424242; font-weight: 400"
                                      >
                                        {{
                                          $t(
                                            'profileForm.degrees.addDocumentBtn',
                                          )
                                        }}
                                      </span>
                                    </div>
                                  </v-btn>
                                </v-col>
                                <v-col
                                  cols="12"
                                  class="text-caption py-0 text-center document-hint"
                                >
                                  {{ $t('profileForm.degrees.documentHint') }}
                                </v-col>
                                <v-col cols="12" class="d-flex justify-center">
                                  <v-btn
                                    color="#FF5353"
                                    variant="text"
                                    size="small"
                                    @click="
                                      form.degrees.value.splice(index, 1),
                                        (tab = tab - 1)
                                    "
                                  >
                                    <span class="text-decoration-underline">{{
                                      $t('profileForm.degrees.deleteBtn')
                                    }}</span>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-sheet>
                          </v-col>
                          <!-- <v-col cols="12">
                            <v-btn
                              v-if="form.degrees.value.length < 5"
                              color="secondary"
                              rounded="lg"
                              @click="addDegree"
                            >
                              {{
                                form.degrees.value.length < 1
                                  ? $t('profileForm.degrees.addBtn')
                                  : $t('profileForm.degrees.addAnotherBtn')
                              }}
                            </v-btn>
                          </v-col> -->
                        </v-row>
                        <div class="pt-8">
                          <error-list :errors="rootErrors" />
                        </div>
                      </v-tabs-window-item>
                    </v-tabs-window>
                  </form-block>
                </v-expand-transition>

                <!-- tab images  -->
                <v-expand-transition>
                  <div v-show="route.query.tab === 'pictures'" class="py-5">
                    <div class="d-flex justify-space-between align-center">
                      <div class="tab-title py-2">
                        <span>Images</span>
                      </div>
                      <div
                        class="cursor-pointer add-img-btn"
                        v-if="form.photos.value.length < MAX_PHOTOS"
                        @click="handleUploadPhotos"
                      >
                        <v-icon>mdi-plus</v-icon>
                        <span>Ajouter une image</span>
                      </div>
                    </div>

                    <v-row>
                      <v-col
                        v-for="photo in form.photos.value"
                        :key="photo.src"
                        cols="6"
                        sm="4"
                        md="3"
                      >
                        <v-img
                          :src="photo.src"
                          :cover="true"
                          class="mb-2"
                          width="100%"
                          aspect-ratio="1"
                          rounded="lg"
                        >
                          <CloseIcon
                            class="position-absolute cursor-pointer"
                            @click="
                              form.photos.value.splice(
                                form.photos.value.indexOf(photo),
                                1,
                              )
                            "
                            style="top: 5px; right: 5px; z-index: 1"
                            color="red"
                          />
                        </v-img>
                        <error-list :errors="photo.error" />
                      </v-col>
                    </v-row>
                    <error-list :errors="form.photos.error" />
                  </div>
                </v-expand-transition>
              </div>
            </v-card>
          </div>

          <div
            v-if="!mobile"
            class="pa-sm-5 pa-2 d-sm-flex justify-sm-end ga-2"
          >
            <v-btn
              @click="() => changeNavTab('previous')"
              variant="outlined"
              rounded="lg"
              color="#757575"
              v-if="route.query.tab !== 'info'"
              class="mt-2"
            >
              <v-icon>mdi-chevron-left</v-icon>
              <span>{{ $t('profileForm.previous') }}</span>
            </v-btn>

            <v-btn
              @click="saveAsDraft"
              :loading="submitDraftLoading"
              variant="outlined"
              rounded="lg"
              color="#12C29B"
              class="mt-2"
            >
              <span>{{ $t('profileForm.save_to_draft') }}</span>
            </v-btn>

            <v-btn
              class="mt-2"
              @click="() => changeNavTab('next')"
              variant="outlined"
              rounded="lg"
              color="#757575"
              v-if="route.query.tab !== 'pictures'"
            >
              <span>{{ $t('profileForm.next') }}</span>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>

          <div v-else class="pa-sm-5 pa-2 ga-2">
            <v-btn
              block
              @click="() => changeNavTab('previous')"
              variant="outlined"
              rounded="lg"
              color="#757575"
              v-if="route.query.tab !== 'info'"
              class="mt-2"
            >
              <v-icon>mdi-chevron-left</v-icon>
              <span>{{ $t('profileForm.previous') }}</span>
            </v-btn>

            <v-btn
              block
              @click="saveAsDraft"
              :loading="submitDraftLoading"
              variant="outlined"
              rounded="lg"
              color="#12C29B"
              class="mt-2"
            >
              <span>{{ $t('profileForm.save_to_draft') }}</span>
            </v-btn>

            <v-btn
              block
              class="mt-2"
              @click="() => changeNavTab('next')"
              variant="outlined"
              rounded="lg"
              color="#757575"
              v-if="route.query.tab !== 'pictures'"
            >
              <span>{{ $t('profileForm.next') }}</span>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="!embedded && false" class="mt-4">
      <v-col class="d-flex justify-center align-center">
        <v-btn color="accent" @click="submit" :loading="submitLoading">
          {{
            currentProfileId
              ? $t('commonForm.updateBtn')
              : $t('save_new_profil')
          }}
          <!-- create or update new profile -->
        </v-btn>
      </v-col>
    </v-row>

    <ProfileAlert
      :dialog="profileAlert"
      @update:cancel="(profileAlert = false), (leave = false)"
      @update:leave="(profileAlert = false), (leave = true)"
    />

    <div
      v-if="mobile && profiles.length > 1"
      @click="showProfileList"
      class="bg-white profile-mobile-lists cursor-pointer position-fixed d-flex justify-center align-center elevation-4 rounded-circle"
    >
      <PresentationIcon :width="23.25" :height="13.5" color="#029978" />
    </div>

    <AlertDeleteProfilDialog
      :display="alertProfile"
      @cancel="alertProfile = false"
      @confirmDelete="() => deleteProfil()"
      :profile-index="profileDeletedIdx"
      :profile-slug="profileDeletedSlug"
      :profile-avatar="ProfileDeletedAvatar"
      :isDeleteDraft="isDeleteDraft"
    />

    <div v-if="false">
      <form-block
        id="newProfil"
        ref="formBlock1"
        :title="$t('profileForm.baseInformations.title')"
        :is-new="currentProfileId === null"
        @update:is-valid="isBaseForm1Valid = $event"
      >
        <v-row>
          <v-col cols="12" class="mt-2 pb-0">
            <div class="text-caption text-secondary">
              {{ $t('profileForm.avatar.label') }}
            </div>
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-column flex-sm-row gap-h align-center pt-0"
          >
            <v-avatar size="80">
              <PractitionerAvatar
                width="64"
                height="64"
                v-show="!form.avatar.src"
                color="#9E9E9E"
              />

              <v-img
                v-show="form.avatar.src"
                :src="form.avatar.src"
                :cover="true"
              ></v-img>
            </v-avatar>

            <div class="d-flex flex-column gap-v mt-4 ml-md-3">
              <div class="d-flex justify-center gap-v">
                <v-btn
                  size="small"
                  color="secondary"
                  rounded="false"
                  @click="handleUploadAvatar"
                >
                  {{ $t('accountForm.avatar.updateBtn') }}
                </v-btn>
              </div>
              <span class="text-subtitle-2 font-weight-light">{{
                $t('accountForm.avatar.caption')
              }}</span>
              <span
                v-if="form.avatar.error"
                class="text-caption text-error animate-slide-down"
                >{{ form.avatar.error }}</span
              >
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <autocomplete-chips
              :key="disciplineEditorKey"
              v-model="form.disciplines.value"
              :label="$t('profileForm.disciplines.label')"
              :items="disciplines"
              item-title="name"
              item-value="id"
              :rules="disciplinesRules"
              :error-messages="form.disciplines.error"
              counter="3"
              :persistent-counter="
                form.disciplines.value.length >= MAX_SPECIALITIES
              "
              color="secondary"
              :no-data-text="$t('profileForm.disciplines.noDataText')"
            >
            </autocomplete-chips>
          </v-col>
        </v-row>
        <v-row class="mb-2">
          <v-col>
            <i18n-t keypath="profileForm.disciplines.suggest">
              <template #link>
                <a href="#" @click.prevent="suggestDisciplineDialog = true">
                  {{ $t('profileForm.disciplines.suggestLinkLabel') }}
                </a>
              </template>
            </i18n-t>
          </v-col>
        </v-row>
        <suggest-discipline-dialog
          :dialog="suggestDisciplineDialog"
          @update:dialog="suggestDisciplineDialog = $event"
        ></suggest-discipline-dialog>
        <v-row v-if="form.disciplines.value.length > 1">
          <v-col>
            <v-select
              v-model="form.mainDiscipline.value"
              :label="$t('profileForm.mainDiscipline.label')"
              :hint="$t('profileForm.mainDiscipline.hint')"
              persistent-hint
              :items="mainDisciplinesItems"
              :error-messages="form.mainDiscipline.error"
              item-title="name"
              item-value="id"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <autocomplete-chips
              v-model="form.practitionerServices.value"
              :label="$t('profileForm.practitionerServices.label')"
              :hint="$t('profileForm.practitionerServices.hint')"
              persistent-hint
              :items="practitionerServices"
              item-title="name"
              item-value="id"
              :rules="practitionerServicesRules"
              :error-messages="form.practitionerServices.error"
              color="secondary"
              :return-object="false"
              :no-data-text="$t('profileForm.practitionerServices.noDataText')"
            >
            </autocomplete-chips>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <autocomplete-chips
              :key="audiencesEditorKey"
              v-model="form.targetAudiences.value"
              :label="$t('profileForm.targetAudiences.label')"
              :items="targetAudiences"
              item-title="name"
              item-value="id"
              :rules="targetAudiencesRules"
              :error-messages="form.targetAudiences.error"
              color="secondary"
            >
            </autocomplete-chips>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <autocomplete-chips
              :key="paymentEditorKey"
              v-model="form.paymentMethods.value"
              :label="$t('profileForm.paymentMethods.label')"
              :items="paymentMethods"
              item-title="name"
              item-value="id"
              :rules="paymentMethodsRules"
              :error-messages="form.paymentMethods.error"
              color="secondary"
            >
            </autocomplete-chips>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <autocomplete-chips
              :key="spokenLanguagesEditorKey"
              v-model="form.spokenLanguages.value"
              :label="$t('profileForm.spokenLanguages.label')"
              :items="languages"
              item-title="nativeName"
              item-value="code"
              :rules="spokenLanguagesRules"
              :error-messages="form.spokenLanguages.error"
              color="secondary"
            >
            </autocomplete-chips>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="form.email.value"
              :label="$t('profileForm.email.label')"
              :hint="$t('profileForm.email.hint')"
              :rules="emailRules"
              :error-messages="form.email.error"
              color="secondary"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <phone
              v-model="form.phone.value"
              :label="$t('profileForm.phone.label')"
              :hint="$t('profileForm.phone.hint')"
              :error-messages="form.phone.error"
              :show-dial-code="false"
              rounded
              required
              @change="onPhoneChange"
              @valide="form.phone.valid = $event"
            ></phone>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="form.webSite.value"
              :label="$t('profileForm.website')"
              :rules="websiteRules"
              :error-messages="form.webSite.error"
              color="secondary"
              placeholder="https://"
            ></v-text-field>
          </v-col>
        </v-row>
      </form-block>

      <form-block
        ref="formBlock2"
        :title="$t('profileForm.presentation.title')"
        @update:is-valid="isForm2Valid = $event"
      >
        <v-row>
          <v-col>
            <v-textarea
              v-model="form.tagline.value"
              :label="$t('profileForm.tagline.label')"
              :hint="$t('profileForm.tagline.hint')"
              :rules="taglineRules"
              :error-messages="form.tagline.error"
              color="secondary"
              rows="2"
              :counter="SHORT_PRESENTATION_MAX_LENGTH"
              persistent-counter
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div>
              <rich-editor
                ref="mainDescriptionEditor"
                :key="mainDescriptionEditorKey"
                v-model:content="form.mainDescription.value"
                contentType="html"
                :label="$t('profileForm.mainDescription.label')"
                :hint="$t('profileForm.mainDescription.hint')"
                :rules="mainDescriptionRules"
                :error-messages="form.mainDescription.error"
                color="secondary"
                :counter="LONG_PRESENTATION_MAX_LENGTH"
                @is-valid="form.mainDescription.valid = $event"
              >
              </rich-editor>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div>
              <rich-editor
                v-model:content="form.sessionDescription.value"
                :key="sessionDescriptionEditorKey"
                contentType="html"
                :label="$t('profileForm.sessionDescription.label')"
                :hint="$t('profileForm.sessionDescription.hint')"
                :rules="sessionDescriptionRules"
                :error-messages="form.sessionDescription.error"
                color="secondary"
                :counter="SESSION_DESCRIPTION_MAX_LENGTH"
                @is-valid="form.sessionDescription.valid = $event"
              >
              </rich-editor>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div>
              <rich-editor
                v-model:content="form.expectedBenefits.value"
                :key="expectedBenefitsEditorKey"
                contentType="html"
                :label="$t('profileForm.expectedBenefits.label')"
                :hint="$t('profileForm.expectedBenefits.hint')"
                :rules="expectedBenefitsRules"
                :error-messages="form.expectedBenefits.error"
                color="secondary"
                :counter="EXPECTED_BENEFITS_MAX_LENGTH"
                @is-valid="form.expectedBenefits.valid = $event"
              >
              </rich-editor>
            </div>
          </v-col>
        </v-row>
      </form-block>

      <form-block :title="$t('profileForm.photos.title')">
        <div class="text-center mb-2">
          {{ $t('profileForm.photos.subtitle') }}
        </div>
        <v-row>
          <v-col
            v-for="photo in form.photos.value"
            :key="photo.src"
            cols="6"
            sm="4"
            md="3"
          >
            <v-img
              :src="photo.src"
              :cover="true"
              class="mb-2"
              width="100%"
              aspect-ratio="1"
              rounded="lg"
            >
              <v-btn
                color="error"
                icon="mdi-close"
                class="position-absolute"
                style="top: 5px; right: 5px; z-index: 1"
                density="compact"
                @click="
                  form.photos.value.splice(form.photos.value.indexOf(photo), 1)
                "
              >
              </v-btn>
            </v-img>
            <error-list :errors="photo.error" />
          </v-col>
          <v-col
            v-if="form.photos.value.length < MAX_PHOTOS"
            cols="6"
            sm="4"
            md="3"
          >
            <div class="w-100" style="aspect-ratio: 1">
              <v-btn
                size="100%"
                rounded="lg"
                color="grey-lighten-3"
                @click="handleUploadPhotos"
                stacked
              >
                <v-icon size="25px" icon="mdi-plus" color="primary"></v-icon>
                <div class="text-primary">
                  {{ $t('profileForm.photos.addBtn') }}
                </div>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <error-list :errors="form.photos.error" />
      </form-block>

      <form-block
        ref="formBlock3"
        :title="$t('profileForm.degrees.title')"
        @update:is-valid="isForm3Valid = $event"
      >
        <div class="text-center mb-2">
          {{ $t('profileForm.degrees.subtitle') }}
        </div>
        <v-row>
          <v-col cols="12">
            <v-sheet
              v-for="(degreeForm, index) in form.degrees.value"
              :key="index"
              border
              rounded="lg"
              class="pa-2 mt-3"
              color="transparent"
            >
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="degreeForm.schoolName.value"
                    :label="$t('profileForm.degrees.schoolName.label')"
                    :rules="degreeRules"
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="degreeForm.discipline.value"
                    :label="$t('profileForm.degrees.discipline.label')"
                    :rules="degreeRules"
                    color="secondary"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="degreeForm.graduationYear.value"
                    :items="years"
                    :label="$t('profileForm.degrees.graduationYear.label')"
                    :rules="requiredRules"
                    color="secondary"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-sheet
                    color="white"
                    width="100%"
                    rounded="xl"
                    class="px-2 d-flex align-center justify-center border-opacity-50"
                    border
                  >
                    <v-select
                      v-model="degreeForm.durationValue.value"
                      :label="$t('profileForm.degrees.durationValue.label')"
                      variant="solo"
                      bg-color="transparent"
                      color="secondary"
                      flat
                      hide-details
                      :items="Array.from({ length: 10 }, (v, i) => i + 1)"
                      style="min-width: 100px"
                    ></v-select>
                    <!--                <v-divider thickness="2" vertical inset class="mx-2"></v-divider>-->
                    <v-select
                      v-model="degreeForm.durationUnit.value"
                      class="flex-0-0"
                      :items="durationUnits"
                      item-title="name"
                      item-value="id"
                      bg-color="transparent"
                      variant="solo"
                      single-line
                      flat
                      hide-details
                      density="compact"
                    ></v-select>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="degreeForm.title.value"
                    :label="$t('profileForm.degrees.description.label')"
                    :rules="degreeRules"
                    color="secondary"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div ref="uppyDocuments"></div>
                  <v-chip
                    v-for="(document, index) in degreeForm.documents.value"
                    :key="document.src"
                    class="ma-1 cursor-pointer"
                    color="secondary"
                    closable
                    @click="() => seeDocument(document.src)"
                    @click:close="degreeForm.documents.value.splice(index, 1)"
                  >
                    {{ document.name }}
                  </v-chip>
                  <v-btn
                    v-if="degreeForm.documents.value.length < MAX_DOCUMENTS"
                    color="secondary"
                    prepend-icon="mdi-plus"
                    size="small"
                    @click="
                      handleUploadDegreeDocuments(degreeForm.documents.value)
                    "
                  >
                    {{ $t('profileForm.degrees.addDocumentBtn') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" class="text-caption">
                  {{ $t('profileForm.degrees.documentHint') }}
                </v-col>
                <v-col cols="12" class="d-flex justify-end">
                  <v-btn
                    color="error"
                    variant="outlined"
                    size="small"
                    @click="form.degrees.value.splice(index, 1)"
                  >
                    {{ $t('profileForm.degrees.deleteBtn') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
          <v-col cols="12">
            <v-btn
              v-if="form.degrees.value.length < 5"
              color="secondary"
              rounded="lg"
              @click="addDegree"
            >
              {{
                form.degrees.value.length < 1
                  ? $t('profileForm.degrees.addBtn')
                  : $t('profileForm.degrees.addAnotherBtn')
              }}
            </v-btn>
          </v-col>
        </v-row>
        <div class="pt-8">
          <error-list :errors="rootErrors" />
        </div>
      </form-block>

      <v-row v-if="!embedded" class="mt-4">
        <v-col class="d-flex justify-center align-center">
          <v-btn color="accent" @click="submit" :loading="submitLoading">
            {{
              currentProfileId
                ? $t('commonForm.updateBtn')
                : $t('save_new_profil')
            }}
            <!-- create or update new profile -->
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script lang="ts" setup>
import { ref, onMounted, nextTick, computed, watch } from 'vue'
import FormBlock from '@/components/common/FormBlock.vue'
import AvatarService from '@/services/avatarService'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'

import PhotoService, {
  setMaxNumberOfFiles as setMaxNumberOfPhotos,
} from '@/services/photoService'
import DocumentService, {
  setMaxNumberOfFiles as setMaxNumberOfDocuments,
} from '@/services/documentService'
import { useI18n } from 'vue-i18n'
import createHttpClient from '@/api/httpClient'
import AutocompleteChips from '@/components/inputs/AutocompleteChips.vue'
import languages from '@/assets/languages.json'
import { EmailRegEx } from '@/utils/regex'
import Phone from '@/components/inputs/phone/Phone.vue'
import RichEditor from '@/components/inputs/RichEditor.vue'
import {
  handleServerErrors,
  resetErrors,
  scrollToFirstError,
} from '@/services/validation'
import MessageService from '@/components/feedback/message/messageService'
import ErrorList from '@/components/forms/ErrorList.vue'
import { useAppStore } from '@/store/app'
import SuggestDisciplineDialog from '@/components/dialog/SuggestDisciplineDialog.vue'
import AlertDeleteProfilDialog from '@/components/dialog/AlertDeleteProfilDialog.vue'
import { useDashboardStatus } from '@/store/dashboard-status/dashboardStatus'
import { useUserStore } from '@/store/user/userStore'
import { useProfileStore } from '@/store/profile/profileStore'
// import { useDialogTimeSlotStore } from '@/store/timeslot/dialogTimeSlotStore'
import PractitionerAvatar from '@/components/PractitionerAvatar.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import PencilIcon from '@/components/icons/PencilIcon.vue'
import ProfileAlert from '@/components/dialog/ProfileAlert.vue'
import CameraIcon from '@/components/icons/CameraIcon.vue'
import PresentationIcon from '@/components/icons/PresentationIcon.vue'
import DiplomeIcon from '@/components/icons/DiplomeIcon.vue'
import ImageIcon from '@/components/icons/ImageIcon.vue'
import InformationIcon from '@/components/icons/InformationIcon.vue'
import SavePublishIcon from '@/components/icons/SavePublishIcon.vue'
import CheckIcon from '@/components/icons/CheckIcon.vue'
import { useDisplay, useTheme } from 'vuetify'
import CheckOutlineIcon from '@/components/icons/CheckOutlineIcon.vue'

// const dialogStore = useDialogTimeSlotStore()
const userStore = useUserStore()
const profileStore = useProfileStore()
const MAX_SPECIALITIES = 3
const MAX_PRACTITIONER_SERVICES = 5
const SHORT_PRESENTATION_MAX_LENGTH = 100
const LONG_PRESENTATION_MAX_LENGTH = 1000
const LONG_PRESENTATION_MIN_LENGTH = 100
const SESSION_DESCRIPTION_MAX_LENGTH = 1000
const EXPECTED_BENEFITS_MAX_LENGTH = 1000
const MAX_PHOTOS = 5
const MAX_DOCUMENTS = 3
const { mdAndDown, mobile, xs, sm, smAndDown, mdAndUp } = useDisplay()

const router = useRouter()
const route = useRoute()

const $t = useI18n().t

const appStore = useAppStore()
const selectedIndex = ref(null)

defineProps({
  embedded: Boolean,
})
const emit = defineEmits(['submitLoading'])
const dashboardState = useDashboardStatus()

const degreeForm = ref({
  schoolName: { value: '', error: '' },
  discipline: { value: '', error: '' },
  title: { value: '', error: '' },
  graduationYear: { value: null, error: '' },
  durationValue: { value: null, error: '', hasError: false },
  durationUnit: { value: 'years', error: '' },
  documents: { value: [], error: '' },
  error: { value: '', error: '' },
})

const initialFormState = {
  avatar: {
    src: '',
    key: '',
    error: '',
  },
  disciplines: {
    value: [],
    error: '',
  },
  mainDiscipline: {
    value: null,
    error: '',
  },
  practitionerServices: {
    value: [],
    error: '',
  },
  targetAudiences: {
    value: [],
    error: '',
  },
  paymentMethods: {
    value: [],
    error: '',
  },
  spokenLanguages: {
    value: [],
    error: '',
  },
  email: {
    value: '',
    error: '',
  },
  webSite: {
    value: '',
    error: '',
  },
  phone: {
    value: '',
    error: '',
    normalizedValue: '',
    valid: true,
  },
  tagline: {
    value: '',
    error: '',
  },
  mainDescription: {
    value: '',
    error: '',
    valid: false,
  },
  sessionDescription: {
    value: '',
    error: '',
    valid: null,
  },
  expectedBenefits: {
    value: '',
    error: '',
    valid: null,
  },
  photos: {
    value: [],
    error: '',
  },
  degrees: {
    value: [],
    error: '',
  },
  isDraft: false,
  DraftId: '',
}

const form = ref({ ...initialFormState })

const resetForm = () => {
  currentProfileId.value = ''
  mainDescriptionEditorKey.value++
  expectedBenefitsEditorKey.value++
  sessionDescriptionEditorKey.value++
  disciplineEditorKey.value++
  audiencesEditorKey.value++
  paymentEditorKey.value++
  spokenLanguagesEditorKey.value++

  form.value = {
    ...form.value,
    mainDescription: { ...form.value.mainDescription, value: '' },
    avatar: { ...form.value.avatar, src: '' },
    disciplines: { ...form.value.disciplines, value: [] },
    mainDiscipline: { ...form.value.mainDiscipline, value: '' },
    practitionerServices: { ...form.value.practitionerServices, value: [] },
    webSite: { ...form.value.webSite, value: '' },
    email: { ...form.value.email, value: '' },
    phone: { ...form.value.phone, value: '' },
    degrees: { ...form.value.degrees, value: [] },
    photos: { ...form.value.photos, value: [] },
    expectedBenefits: { ...form.value.expectedBenefits, value: '' },
    sessionDescription: { ...form.value.sessionDescription, value: '' },
    targetAudiences: { ...form.value.sessionDescription, value: [] },
    paymentMethods: { ...form.value.paymentMethods, value: [] },
    spokenLanguages: { ...form.value.spokenLanguages, value: [] },
    tagline: { ...form.value.tagline, value: '' },
  }
  DraftId.value = ''
}

const DraftId = ref('')
const isDraft = ref(false)
const isSynced = ref(false)

const initialForm = ref({})
const isInitializing = ref(false)
const isFormDirty = ref(false)

watch(
  () => form.value.disciplines.value,
  () => {
    if (
      !form.value.disciplines.value.includes(form.value.mainDiscipline.value)
    ) {
      form.value.mainDiscipline.value = null
    }

    if (form.value.disciplines.value.length == 1) {
      form.value.mainDiscipline.value = form.value.disciplines.value[0]
    }
  },
)

const currentProfileId = ref(null)
const suggestDisciplineDialog = ref(false)
const alertProfile = ref(false)
const profiles = ref([])
const mainDescriptionEditor = ref()

const targetAudiences = ref([
  { id: 'all', name: $t('profileForm.targetAudiences.audience.all') },
  { id: 'adults', name: $t('profileForm.targetAudiences.audience.adults') },
  { id: 'children', name: $t('profileForm.targetAudiences.audience.children') },
  {
    id: 'adolescents',
    name: $t('profileForm.targetAudiences.audience.adolescents'),
  },
  { id: 'elderly', name: $t('profileForm.targetAudiences.audience.elderly') },
  {
    id: 'pregnantWomen',
    name: $t('profileForm.targetAudiences.audience.pregnantWomen'),
  },
  { id: 'women', name: $t('profileForm.targetAudiences.audience.women') },
  { id: 'men', name: $t('profileForm.targetAudiences.audience.men') },
])

const paymentMethods = ref([
  { id: 'cash', name: $t('profileForm.paymentMethods.method.cash') },
  {
    id: 'creditCard',
    name: $t('profileForm.paymentMethods.method.creditCard'),
  },
  { id: 'check', name: $t('profileForm.paymentMethods.method.check') },
  { id: 'transfer', name: $t('profileForm.paymentMethods.method.transfer') },
])

const durationUnits = computed(() => [
  { id: 'years', name: $t('profileForm.degrees.durationUnit.years', 2) },
  { id: 'months', name: $t('profileForm.degrees.durationUnit.months') },
  { id: 'weeks', name: $t('profileForm.degrees.durationUnit.weeks') },
  { id: 'days', name: $t('profileForm.degrees.durationUnit.days', 2) },
])

const durationUnits_old = ref([
  {
    id: 'years',
    name: $t(
      'profileForm.degrees.durationUnit.years',
      degreeForm.value.durationValue.value,
    ),
  },
  { id: 'months', name: $t('profileForm.degrees.durationUnit.months') },
  { id: 'weeks', name: $t('profileForm.degrees.durationUnit.weeks') },
])

const mainDisciplinesItems = computed(() => {
  // return only disciplines that are selected
  return disciplines.value.filter(discipline =>
    form.value.disciplines.value.some(
      selectedDiscipline => selectedDiscipline === discipline.id,
    ),
  )
})

const disciplinesRules = [
  (v: any) => v.length || $t('profileForm.disciplines.error.required'),
  (v: any) =>
    (v && v.length <= MAX_SPECIALITIES) ||
    $t('profileForm.disciplines.error.max'),
]
const practitionerServicesRules = [
  (v: any) =>
    (v && v.length <= MAX_PRACTITIONER_SERVICES) ||
    $t('profileForm.practitionerServices.error.max'),
]
const targetAudiencesRules = [
  (v: any) => v.length || $t('profileForm.targetAudiences.error.required'),
  // Disallow "all" if other audiences are selected
  (v: any) => {
    if (v && v.length > 1 && v.some((audience: any) => audience === 'all')) {
      return $t('profileForm.targetAudiences.error.allWithOthers')
    }
    return true
  },
]
const paymentMethodsRules = [
  (v: any) => v.length || $t('profileForm.paymentMethods.error.required'),
]
const spokenLanguagesRules = [
  (v: any) => v.length || $t('profileForm.spokenLanguages.error.required'),
  (v: any) =>
    5 >= v.length || $t('profileForm.spokenLanguages.error.maximum5Languages'),
]
const emailRules = [
  (v: any) =>
    !v.length || EmailRegEx.test(v) || $t('commonForm.email.error.invalid'),
]

const isValidDomain = (domain: string) => {
  const regex =
    /^(?!:\/\/)([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/
  return regex.test(domain) || $t('profileForm.validDomain')
}

const websiteRules = [
  (url: string) => {
    if (!url) return true
    try {
      const parsedUrl = new URL(url)
      const hostname = parsedUrl.hostname
      return isValidDomain(parsedUrl.hostname)
    } catch (_) {
      return $t('profileForm.invalidUrl')
    }
  },
]
const taglineRules = [
  (v: any) =>
    v.length <= SHORT_PRESENTATION_MAX_LENGTH ||
    $t('profileForm.tagline.error.max', {
      maxLength: SHORT_PRESENTATION_MAX_LENGTH,
    }),
]
const mainDescriptionRules = [
  (v: any) => !!v || $t('profileForm.mainDescription.error.required'),
  (v: any) =>
    v.length <= LONG_PRESENTATION_MAX_LENGTH ||
    $t('profileForm.mainDescription.error.max', {
      maxLength: LONG_PRESENTATION_MAX_LENGTH,
    }),
  (v: any) =>
    v.length > LONG_PRESENTATION_MIN_LENGTH ||
    $t('profileForm.mainDescription.error.min', {
      minLength: LONG_PRESENTATION_MIN_LENGTH,
    }),
]
const sessionDescriptionRules = [
  (v: any) =>
    v.length <= SESSION_DESCRIPTION_MAX_LENGTH ||
    $t('profileForm.sessionDescription.error.max', {
      maxLength: SESSION_DESCRIPTION_MAX_LENGTH,
    }),
]
const expectedBenefitsRules = [
  (v: any) =>
    v.length <= EXPECTED_BENEFITS_MAX_LENGTH ||
    $t('profileForm.expectedBenefits.error.max', {
      maxLength: EXPECTED_BENEFITS_MAX_LENGTH,
    }),
]

const degreeRules = [
  (v: any) => !!v || $t('commonForm.error.required'),

  (v: any) => v.length <= 100 || $t('profileForm.degree_hint'),
]

const requiredRules = [(v: any) => !!v || $t('commonForm.error.required')]
const isBaseForm1Valid = ref(true)
const isForm1Valid = computed(
  () => isBaseForm1Valid.value && form.value.phone.valid !== false,
)
const isForm2Valid = computed(
  () =>
    mainDescriptionEditor.value.isValid() !== false &&
    form.value.sessionDescription.valid !== false &&
    form.value.expectedBenefits.valid !== false,
)
const isForm3Valid = ref(true)
const formBlock1 = ref()
const formBlock2 = ref()
const formBlock3 = ref()

const currentYear = new Date().getFullYear()
const yearsRange = 80
const years = Array.from({ length: yearsRange }, (v, k) => currentYear - k)
const currentDocuments = ref([])

const handleUploadAvatar = () => {
  // @ts-ignore
  AvatarService.getPlugin('Dashboard').openModal()
}
const handleUploadPhotos = () => {
  setMaxNumberOfPhotos(MAX_PHOTOS - form.value.photos.value.length)
  // @ts-ignore
  PhotoService.getPlugin('Dashboard').openModal()
}
const handleUploadDegreeDocuments = currentFiles => {
  setMaxNumberOfDocuments(MAX_DOCUMENTS - currentFiles.length)
  currentDocuments.value = currentFiles
  // @ts-ignore
  DocumentService.getPlugin('Dashboard').openModal()
}

AvatarService.on('complete', result => {
  const avatar = result.successful[0]
  form.value.avatar.src = avatar.preview
  form.value.avatar.key = avatar.uploadURL
})

PhotoService.on('complete', result => {
  const uploadedPhotos = result.successful
  uploadedPhotos
    .slice(0, MAX_PHOTOS - form.value.photos.value.length)
    .forEach((photo: any) => {
      form.value.photos.value.push({
        src: photo.preview,
        key: photo.uploadURL,
      })
    })
})

DocumentService.on('complete', result => {
  const uploadedDocuments = result.successful
  uploadedDocuments
    .slice(0, MAX_PHOTOS - currentDocuments.value.length)
    .forEach((document: any) => {
      currentDocuments.value.push({
        src: document.preview,
        key: document.uploadURL,
        name: document.name,
      })
    })
})

const tab = ref(0)

const onPhoneChange = async phoneObject => {
  form.value.phone.normalizedValue = phoneObject.number
}

const addDegree = () => {
  const newDegree = JSON.parse(JSON.stringify(degreeForm.value))
  form.value.degrees.value.push(newDegree)
  tab.value = form.value.degrees.value.length - 1
}

const disciplines = ref([])
const practitionerServices = ref([])
const client = createHttpClient()

const fetchSelectData = () => {
  Promise.all([
    client.get('/discipline/list'),
    client.get('/practitioner-service/list'),
  ])
    .then(([disciplinesResponse, practitionerServicesResponse]) => {
      disciplines.value = disciplinesResponse.data
      practitionerServices.value = practitionerServicesResponse.data
    })
    .catch(error => {
      console.error('Error fetching data:', error) // Gestion des erreurs
    })
}

const getFormValues = form => {
  return Object.keys(form).reduce((acc, key) => {
    if (typeof form[key] === 'object' && form[key].value) {
      acc[key] = form[key].value
    }
    return acc
  }, {})
}

const submitLoading = ref(false)
const submitDraftLoading = ref(false)

const rootErrors = ref<string[]>([])
const newProfile = ref(false)
const deleteId = ref('')
const block1StatusColor = ref('')
const block2StatusColor = ref('')
const block3StatusColor = ref('')

const submit = async () => {
  rootErrors.value = []

  if (!isDraft.value) {
    block1StatusColor.value = ''
    block2StatusColor.value = ''
    block3StatusColor.value = ''

    resetErrors(form.value)
    await formBlock1.value.isValid()
    await formBlock2.value.isValid()
    await formBlock3.value.isValid()

    await nextTick()

    if (!isForm1Valid.value) {
      block1StatusColor.value = 'red'
    }

    if (!isForm2Valid.value) {
      block2StatusColor.value = 'red'
    }

    if (!isForm3Valid.value) {
      block3StatusColor.value = 'red'
    }

    if (
      !isForm1Valid.value ||
      !isForm2Valid.value ||
      !(await formBlock3.value.isValid())
    ) {
      scrollToFirstError()
      return Promise.reject('Form not valid')
    }
  }

  const data = {
    ...getFormValues(form.value),
    phone: form.value.phone.normalizedValue,
    avatar: form.value.avatar.key || '',
    disciplines: form.value.disciplines.value,
    practitionerServices: form.value.practitionerServices.value,
    targetAudiences: form.value.targetAudiences.value,
    paymentMethods: form.value.paymentMethods.value,
    spokenLanguages: form.value.spokenLanguages.value,
    photos: form.value.photos.value.map((photo: any) => photo.key),
    degrees: form.value.degrees.value.map((degree: any) => ({
      schoolName: degree.schoolName.value,
      discipline: degree.discipline.value,
      title: degree.title.value,
      graduationYear: degree.graduationYear.value,
      durationValue: degree.durationValue.value,
      durationUnit: degree.durationUnit.value,
      documents: degree.documents.value.map((document: any) => document.key),
    })),
    draftId: DraftId.value,
    isDraft: isDraft.value,
  }

  if (isDraft.value) {
    submitDraftLoading.value = true
  } else {
    submitLoading.value = true
  }
  const client = createHttpClient('/profile')
  const url = currentProfileId.value ? `/${currentProfileId.value}` : '/'

  return client
    .post(url, data)
    .then(response => {
      currentProfileId.value = response.data.id
      if (!newProfile.value) {
        if (!isDraft.value) {
          MessageService.success($t('dashboard.account.update.success'))
        }
      } else {
        if (!isDraft.value) {
          // update dashboard status
          dashboardState.fetchDashboardStatus()
          MessageService.success($t('dashboard.profile.success'))
          newProfile.value = false
        }
      }

      isFormDirty.value = false

      if (isDraft.value) {
        const index = profiles.value.findIndex(
          profile => profile.draft.id === response.data.draft.id,
        )

        if (index !== -1) {
          profiles.value[index] = {
            ...profiles.value[index],
            draft: response.data.draft,
          }
          selectedIndex.value = index + 1
          assignDataToForm(profiles.value[index])
        } else {
          profiles.value.push(response.data)
          selectedIndex.value = profiles.value.length
          assignDataToForm(response.data)
        }
        profileStore.totalProfile = profiles.value.length

        return
      }

      userStore.refreshToken() // refresh token
      loadProfiles()
      profileStore.refreshProfiles()
      // dialogStore.resetDialogTimeSlotCreationProfileAddress()
      return Promise.resolve()
    })
    .catch(async err => {
      if (err.response.status === 422) {
        rootErrors.value = handleServerErrors(form.value, err.response.data)
        await nextTick()
        scrollToFirstError()
      } else {
        MessageService.error($t('dashboard.account.update.error'))
      }
      return Promise.reject('Error submitting form')
    })
    .finally(() => {
      submitLoading.value = false
      submitDraftLoading.value = false
    })
}

const loadProfiles = () => {
  isInitializing.value = true
  if (profileStore.loadProfile) return
  appStore.setInnerLoading(true)
  profileStore.loadProfile = true
  const client = createHttpClient('/profile')
  client
    .get('/')
    .then(response => {
      profiles.value = response.data
      if (profiles.value.length === 0) {
        resetForm()

        isInitializing.value = false
        newProfile.value = true

        // if (route.query.selected_profile) {
        //   const updatedQuery = { ...route.query }
        //   delete updatedQuery.selected_profile // Retirer le paramètre

        //   router.push({ query: updatedQuery }) // Rediriger avec le paramètre retiré
        // }

        router.push({
          query: { ...route.query, selected_profile: 'New' },
        })

        return
      }

      let currentProfile = null
      profileStore.totalProfile = profiles.value.length

      if (
        route.query.selected_profile &&
        Number(route.query.selected_profile) <= profileStore.totalProfile
      ) {
        currentProfile =
          profiles.value[Number(route.query.selected_profile) - 1]
        selectedIndex.value = route.query.selected_profile
      } else {
        currentProfile = profiles.value[0]
        selectedIndex.value = 1
      }

      assignDataToForm(currentProfile)
    })
    .catch(err => {
      console.error(err)
      MessageService.error($t('common.error.errorHasOccurred'))
    })
    .finally(() => {
      appStore.setInnerLoading(false)
      profileStore.loadProfile = false
    })
}

watch(submitLoading, value => {
  emit('submitLoading', value)
})

defineExpose({
  submit,
})

onMounted(async () => {
  fetchSelectData()
  loadProfiles()
})

// const profileLoading = ref(false)

const profileLoading = computed(() => {
  return profileStore.loadProfile
})

const deleteProfil = async () => {
  try {
    await client.delete(
      `/profile/${deleteId.value}?isDraft=${isDeleteDraft.value}`,
    )

    MessageService.success($t('dashboard.profile.delete_message'))
    // update dashboard status
    dashboardState.fetchDashboardStatus()
    profileStore.refreshProfiles()
    return Promise.resolve()
  } catch (err) {
    MessageService.error($t('dashboard.profile.delete_error'))
  } finally {
    resetForm()
    await loadProfiles()
    alertProfile.value = false
  }
}

const profileSelected = (profil, idx) => {
  const newProfilForm = document.getElementById('newProfil')
  newProfilForm.scrollIntoView({ behavior: 'smooth' })
  selectedIndex.value = idx + 1
  assignDataToForm(profil)
}

const mainDescriptionEditorKey = ref(0)
const expectedBenefitsEditorKey = ref(0)
const sessionDescriptionEditorKey = ref(0)
const disciplineEditorKey = ref(0)
const audiencesEditorKey = ref(0)
const paymentEditorKey = ref(0)
const spokenLanguagesEditorKey = ref(0)
const prevTab = ref('')

const new_profile = () => {
  const newProfilForm = document.getElementById('newProfil')
  newProfilForm.scrollIntoView({ behavior: 'smooth' })
  newProfile.value = true
  resetForm()
}

watch(
  () => profileStore.createNewProfils,
  () => {
    if (profileStore.createNewProfils) {
      isInitializing.value = true
      rootErrors.value = []
      block1StatusColor.value = ''
      block2StatusColor.value = ''
      block3StatusColor.value = ''
      router.push({
        query: { tab: 'info', selected_profile: 'New' },
      })
      new_profile()
      profileStore.createNewProfils = false

      setTimeout(() => {
        isInitializing.value = false
      }, 0)
    }
  },
)

const assignDataToForm = data => {
  const payload = data.draft

  currentProfileId.value = data.id

  block1StatusColor.value = ''
  block2StatusColor.value = ''
  block3StatusColor.value = ''
  rootErrors.value = []
  isInitializing.value = true
  initialForm.value = JSON.parse(JSON.stringify(payload)) // Deep clone

  mainDescriptionEditorKey.value++
  expectedBenefitsEditorKey.value++
  sessionDescriptionEditorKey.value++
  disciplineEditorKey.value++
  audiencesEditorKey.value++
  paymentEditorKey.value++
  spokenLanguagesEditorKey.value++
  form.value.avatar.src = payload.avatar?.url
  form.value.disciplines.value = payload.disciplines.map(
    (discipline: any) => discipline.id,
  )
  form.value.mainDiscipline.value = payload.mainDiscipline?.id
  form.value.practitionerServices.value = payload.services.map(
    (service: any) => service.id,
  )
  form.value.targetAudiences.value = payload.targetAudiences
  form.value.paymentMethods.value = payload.paymentMethods
  form.value.spokenLanguages.value = payload.spokenLanguages
  form.value.email.value = payload.publicEmail || ''
  form.value.webSite.value = payload.webSite || ''
  form.value.phone.value = payload.publicPhone || ''
  form.value.tagline.value = payload.tagline || ''
  form.value.mainDescription.value = payload.mainDescription || ''
  form.value.sessionDescription.value = payload.sessionDescription || ''
  form.value.expectedBenefits.value = payload.expectedBenefits || ''
  form.value.photos.value = payload.photos.map((photo: any) => ({
    src: photo.url,
    key: photo.id,
  }))
  form.value.degrees.value = payload.degrees.map((degree: any) => ({
    schoolName: { value: degree.schoolName },
    discipline: { value: degree.discipline },
    title: { value: degree.title },
    graduationYear: { value: degree.graduationYear },
    durationValue: { value: degree.durationValue },
    durationUnit: { value: degree.durationUnit },
    documents: {
      value: degree.verificationDocuments.map(
        (document: any, docIndex: number) => ({
          src: document.url,
          key: document.id,
          name: document.name || `Document ${docIndex + 1}`,
        }),
      ),
    },
  }))

  DraftId.value = payload.id
  isDraft.value = payload.isDraft
  isSynced.value = payload.isSynced

  router.push({
    query: { ...route.query, selected_profile: selectedIndex.value },
  })

  setTimeout(() => {
    isInitializing.value = false
  }, 0)
}

const seeDocument = url => {
  window.open(url, '_blank')
}

watch(
  () => form.value,
  newVal => {
    if (isInitializing.value) return false

    isFormDirty.value =
      JSON.stringify(newVal) !== JSON.stringify(initialForm.value)
  },
  { deep: true },
)

const leave = ref(false)
const profileAlert = ref(false)

onBeforeRouteLeave((to, from, next) => {
  if (isFormDirty.value) {
    profileAlert.value = true // Afficher la modale
    const unwatch = watch(leave, value => {
      if (value) {
        next() // Continuer la navigation
      } else {
        next(false) // Annuler la navigation
      }
      unwatch() // Arrêter l'écoute pour éviter des exécutions inutiles
    })
  } else {
    next() // Pas de modifications, continuer
  }
})

const changeTab = (v: string) => {
  prevTab.value = route.query.tab as string

  router.push({
    name: 'DashboardProfile',
    query: { ...route.query, tab: v },
  })
}

const validtabs = ref(['info', 'presentation', 'degrees', 'pictures'])

watch(
  () => route.query.tab,
  () => {
    const currentTab = route.query.tab as string

    if (validtabs.value.includes(currentTab)) {
      return currentTab
    } else {
      return router.replace({
        name: 'DashboardProfile',
        query: { ...route.query, tab: 'info' },
      })
    }
  },
  { immediate: true },
)

const validateTab = async () => {
  if (isDraft.value) return

  switch (prevTab.value) {
    case 'info':
      await formBlock1.value.isValid()

      if (!isForm1Valid.value) {
        block1StatusColor.value = 'red'
      } else {
        block1StatusColor.value = 'green'
      }
      break

    case 'presentation':
      await formBlock2.value.isValid()

      if (!isForm2Valid.value) {
        block2StatusColor.value = 'red'
      } else {
        block2StatusColor.value = 'green'
      }
      break
    case 'degrees':
      await formBlock3.value.isValid()

      if (!isForm3Valid.value) {
        block3StatusColor.value = 'red'
      } else {
        block3StatusColor.value = 'green'
      }
      break
  }
}
watch(
  () => prevTab.value,
  async () => {
    validateTab()
  },
  { immediate: true },
)

watch(
  () => route.query.selected_profile,
  () => {
    if (Number(route.query.selected_profile) > profileStore.totalProfile) {
      return router.replace({
        name: 'DashboardProfile',
        query: { ...route.query, selected_profile: 1 },
      })
    }
  },
  { immediate: true },
)

// watch form tab info
watch(
  () => [
    form.value.webSite.value,
    form.value.phone.value,
    form.value.email.value,
    form.value.disciplines.value,
    form.value.mainDiscipline.value,
    form.value.practitionerServices.value,
    form.value.targetAudiences.value,
    form.value.paymentMethods.value,
    form.value.spokenLanguages.value,
  ],
  () => {
    if (isInitializing.value) return false

    block1StatusColor.value = 'yellow'
  },
  { deep: true },
)

// watch form tab presentation
watch(
  () => [
    form.value.expectedBenefits.value,
    form.value.sessionDescription.value,
    form.value.tagline.value,
    form.value.mainDescription.value,
  ],
  () => {
    if (isInitializing.value) return false

    block2StatusColor.value = 'yellow'
  },
  { deep: true },
)

const changeNavTab = (type: string) => {
  prevTab.value = route.query.tab as string

  const currentTab = route.query.tab as string
  const currentIndex = validtabs.value.indexOf(currentTab)

  validateTab()
  let newIndex = currentIndex

  if (type === 'next') {
    newIndex = currentIndex + 1
  } else if (type === 'previous') {
    newIndex = currentIndex - 1
  }

  const newTab = validtabs.value[newIndex]

  router.push({
    query: { ...route.query, tab: newTab },
  })
}

const profileDeletedIdx = ref()
const profileDeletedSlug = ref()
const ProfileDeletedAvatar = ref('')
const isDeleteDraft = ref(false)

const handleDeleteProfile = (profil, idx) => {
  isDeleteDraft.value = false
  profileDeletedIdx.value = idx + 1
  if (profil.id) {
    deleteId.value = profil.id
    profileDeletedSlug.value = profil.slug
    ProfileDeletedAvatar.value = profil?.avatar?.url
  } else {
    deleteId.value = profil.draft.id
    isDeleteDraft.value = true

    profileDeletedSlug.value = ''
    ProfileDeletedAvatar.value = profil?.draft.avatar?.url
  }
  alertProfile.value = true
}

// const tabStatus = ref([
//   validtabs.value[0]: {

//   }
// ])

const showProfileList = () => {
  router.push({
    name: 'DashboardMobileProfilLists',
  })
}

const saveAsDraft = async () => {
  isDraft.value = true
  await submit()
}
</script>

<style lang="scss">
.profile-selected {
  background-color: rgba(0, 0, 0, 0.04);
}
.uppy-StatusBar-actionBtn--done {
  border-radius: 3px;
  display: block !important;
  background-color: #1a73e9;
  color: white;
  outline: none;
}

.uppy-StatusBar-actionBtn--done:hover {
  color: white;
}

.uppy-StatusBar-actionBtn--done:focus {
  background-color: #1a73e9;
  color: white;
}

.uppy-Dashboard-close {
  background-color: red;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-sizing: border-box;
}
.uppy-Dashboard-close span {
  line-height: 1;
  padding-bottom: 6px;
}

.uppy-Dashboard-overlay {
  z-index: 1006 !important;
}

.uppy-Dashboard .uppy-Dashboard-inner {
  z-index: 1010 !important;
}

.profile-slug {
  font-size: 23px;
  font-weight: 400;
  line-height: 32.2px;
}

.profil-tagline {
  font-size: 13px;
  color: #616161;
  line-height: 19.5px;
  font-weight: 400;
}

.profile-lists {
  width: 400px;
}

.block-form {
  min-width: 720px;
}

.detail-title {
  background-color: #0299781a;
  padding: 16px 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22.4px;
  color: #007058;
}

// .block-content {
//   background-color: #C2C2C2;
//   min-height: 500px;
// }

.block-profile {
  padding: 12px 24px;
  // height: 104px;
}

.visio-icon {
  position: absolute;
  bottom: -3px;
  right: 5px;
  background-color: #ffffff;
  border-radius: 100%;
  width: 20.8px;
  height: 20.8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.6px solid #000;
  border-color: #e0e0e0;
}

.tab-header {
  font-size: 16px;
}

label {
  color: #616161;
  font-size: 14px;
}

.tab-title {
  color: #424242;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.add-img-btn {
  color: #029978;
  font-size: 12px;
  font-weight: 400;
}

.document-hint {
  font-size: 10px;
  color: #616161;
}

.c-badge {
  background-color: #eaf3ff;
  padding: 8px 12px;
}

.c-badge-draft {
  background-color: #c4fff2;
  padding: 8px 12px;
}

.c-badge-draft span {
  font-size: 10px;
  color: #007058;
  font-weight: 600;
}

.c-badge span {
  font-size: 10px;
  color: #0a5bc7;
  font-weight: 600;
}

.profile-mobile-lists {
  width: 48px;
  z-index: 1000;
  height: 48px;
  top: 45%;
  right: 4%;
  transform: translateY(-60%);
}

:deep {
  .active-tab {
    border-radius: 0px !important;
    color: #004838;
    font-weight: 700;
    line-height: 22.4px;
  }
  .v-tab {
    border-radius: 0px !important;
  }
}

@media (max-width: 599px) {
  .detail-title {
    padding: 8px 10px;
  }
}
</style>
