<script setup lang="ts">
import { defineEmits, ref, onMounted, watch, defineProps } from 'vue'
import { useDisplay, useTheme } from 'vuetify'
import { useUserStore } from '@/store/user/userStore'
import createHttpClient from '@/api/httpClient'
import { useI18n } from 'vue-i18n'
import MessageService from '@/components/feedback/message/messageService'
import { useDashboardStatus } from '@/store/dashboard-status/dashboardStatus'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'

import { useStripe } from '@/shared/payment'

const props = defineProps({
  dialog: Boolean,
})
const { t } = useI18n()
const emit = defineEmits(['update:close'])
const {
  stripe,
  stripeError,
  stripeCard,
  stripeCardExpiry,
  stripeCardCvc,
  initStripe,
} = useStripe(useTheme())

const loading = ref(false)
const firstname = ref('')
const lastname = ref('')
const userStore = useUserStore()
const globalErrors = ref()
const subscriptionStore = useSubscriptionStore()

const fieldFocus = field => {
  setTimeout(() => {
    field.focus()
  }, 10)
}

const dashboardState = useDashboardStatus()

watch(
  async () => props.dialog,
  newVal => {
    if (newVal) {
      initStripe()
    }
  },
  { immediate: true },
)

const onSubmit = async event => {
  globalErrors.value = ''
  if (stripeError.value.length) {
    return false
  }

  loading.value = true
  await stripe.value
    .createPaymentMethod({
      type: 'card',
      card: stripeCard.value,
      billing_details: {
        email: userStore.user.email,
        name: firstname.value + ' ' + lastname.value,
      },
    })
    .then(result => {
      if (result.error) {
        globalErrors.value = result.error.message
        loading.value = false
      } else {
        updateCard(result.paymentMethod.id)
      }
    })
    .catch(err => {
      console.log('err ===>>>', err)
    })
}

const updateCard = async (pm_id: string) => {
  subscriptionStore
    .updateUserCard(pm_id) // Update card
    .then(clientSecret => {
      stripe.value
        .confirmCardSetup(clientSecret, {
          payment_method: {
            card: stripeCard.value,
            billing_details: {
              email: userStore.user.email,
            },
          },
        })
        .then(result => {
          if (result.error) {
            loading.value = false
            globalErrors.value = result.error.message
          } else {
            loading.value = false
            emit('update:close')
            MessageService.success(
              t('dashboard.subscription.update_card_success'),
            )
            subscriptionStore.fetchCardInfo()
            // update dashboard status
            dashboardState.fetchDashboardStatus()
          }
        })
    })
    .catch(error => {
      console.log('an error has occured ===>>>', error.response.data.message)
      loading.value = false
      globalErrors.value = error.response.data.message
    })
}

const deleteCB = async () => {
  const res = await stripe.value
}
</script>

<template>
  <v-dialog
    :model-value="dialog"
    persistent
    max-width="600"
    transition="dialog-top-transition"
  >
    <v-card color="white">
      <v-card-title
        class="d-flex pa-1 justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span class="font-weight-bold title">{{
          t('dashboard.subscription.updateCard')
        }}</span>

        <img
          style="position: absolute; right: 10px"
          class="cursor-pointer mr-2"
          @click="emit('update:close')"
          src="@/assets/_close.svg"
        />
      </v-card-title>

      <v-card-text class="px-md-15">
        <div>
          <label>{{ t('dashboard.subscription.lastname') }}</label>
          <v-text-field
            rounded="lg"
            v-model="lastname"
            class=""
            :placeholder="t('dashboard.subscription.lastname')"
            density="compact"
            type="text"
          />
        </div>

        <div>
          <label>{{ t('dashboard.subscription.firstname') }}</label>

          <v-text-field
            rounded="lg"
            v-model="firstname"
            class=""
            :placeholder="`${t('dashboard.subscription.firstname')}`"
            type="text"
            density="compact"
          />
        </div>

        <div class="d-flex flex-column ga-sm-2">
          <label>{{ t('register.card_number') }}</label>
          <v-card color="white" elevation="0" border>
            <div class="pa-3 py-3">
              <card-input
                @update:focused="fieldFocus(stripeCard)"
                :error-messages="stripeError"
              >
                <div id="card-element">
                  <!-- Elements will create input elements here -->
                </div>
              </card-input>
            </div>
            <v-divider />
            <v-card-text border class="pa-0">
              <div class="d-sm-flex">
                <div class="w-sm-50 border-custom pa-3">
                  <card-input
                    placeholder="MM / AA"
                    @update:focused="fieldFocus(stripeCardExpiry)"
                    hide-details
                  >
                    <div id="card-expiry">
                      <!-- Elements will create input elements here -->
                    </div>
                  </card-input>
                </div>
                <div class="w-sm-50 pa-3">
                  <card-input
                    placeholder="***"
                    @update:focused="fieldFocus(stripeCardCvc)"
                    hide-details
                  >
                    <div id="card-cvc" style="width: 100%">
                      <!-- Elements will create input elements here -->
                    </div>
                  </card-input>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>

        <div class="text-red text-caption text-center py-2">
          {{ globalErrors }}
        </div>

        <div class="d-sm-flex mt-5 ga-2">
          <v-btn
            @click="emit('update:close')"
            class="w-sm-50 w-100"
            color="#E0E0E0"
          >
            <span class="text-gray-80">Annuler</span>
          </v-btn>

          <v-btn
            type="submit"
            @click="onSubmit"
            :loading="loading"
            color="secondary"
            class="mt-sm-0 mt-2 w-sm-50 w-100"
            >{{ t('dashboard.subscription.saveBtn') }}</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.border-custom {
  border-right: 1px solid #ccc;
}
.title {
  color: #007058;
  font-size: 23px;
}
@media (max-width: 599px) {
  .border-custom {
    border-right: none;
    border-bottom: 1px solid #ccc;
  }

  .title {
    color: #007058;
    font-size: 16px !important;
  }

  .current-description {
    font-size: 15px !important;
    color: #616161;
  }
}
</style>
